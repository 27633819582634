body {
  min-height: 100%;
}
.border {
  /* background-color: #000; */
  max-width: 1140px;
  width: 100%;
  /* background:
    linear-gradient(to right, black 2px, transparent 2px) 0 0,
    linear-gradient(to right, black 2px, transparent 2px) 0 100%,
    linear-gradient(to left, black 2px, transparent 2px) 100% 0,
    linear-gradient(to left, black 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, black 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, black 2px, transparent 2px) 100% 0,
    linear-gradient(to top, black 2px, transparent 2px) 0 100%,
    linear-gradient(to top, black 2px, transparent 2px) 100% 100%; */

  background-repeat: no-repeat;
  background-size: 20px 20px;
}
#root *::-webkit-scrollbar {
  width: 4px;
}
#root *::-webkit-scrollbar-thumb {
  background: #ff692b;
}
.borderupcoming {
  margin-top: -2px;
  /* background-color: #000; */
  max-width: 1140px;
  width: 100%;
  /* background:
  linear-gradient(to right, black 2px, transparent 2px) 0 0,
    linear-gradient(to right, black 2px, transparent 2px) 0 100%,
    linear-gradient(to left, black 2px, transparent 2px) 100% 0%,
    linear-gradient(to left, black 2px, transparent 2px) 100% 100%,
    linear-gradient(to top, black 2px, transparent 2px) 0 100%,
    linear-gradient(to top, black 2px, transparent 2px) 100% 100%; */

  background-repeat: no-repeat;
  background-size: 20px 20px;
}
/* .bordertrait {
    margin-top: -2px;
    background-color: #000;
    max-width: 1140px;
    width: 100%;
    min-height: 370px;
    background:
    linear-gradient(to right, black 2px, transparent 2px) 0 0,
    linear-gradient(to left, black 2px, transparent 2px) 100% 0;

  background-repeat: no-repeat;
  background-size: 20px 20px;
} */
.carousel-container {
  max-width: 1140px;
}
.carousel-item-padding-40-px {
  max-width: 360px;
  max-width: 360px;
}
.react-multi-carousel-track {
  width: 1932px;
  height: 960px;
  gap: 16px;
}
.custom-dot-list-style {
  max-width: 1140px;
  background-color: #faf9f5;
  padding-top: 20px;
}
.inactive {
  position: relative;
  width: 200px;
  z-index: 0;
  margin-bottom: 0px;
  cursor: pointer;
  width: 100px;
  color: #000000;
}
.active {
  z-index: 0;
  cursor: pointer;
  color: #fb7f05;
  width: 100px;
}

@media only screen and (max-width: 1269px) {
  .react-multi-carousel-track {
    width: 750px;
    gap: 43px;
  }
}
@media only screen and (max-width: 899px) {
  .banner-video{
    position: relative;
    margin: auto auto;
    top: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 808px) {
  .react-multi-carousel-track {
    width: 360px;
    gap: 0;
  }
  .active,
  .inactive {
    width: 29px;
  }
}

@media only screen and (max-width: 471px) {
  .carousel-container {
    height: 850px;
  }
  .react-multi-carousel-track {
    width: 350px;
  }
}


.arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 45px;
  z-index: 1000;
  border: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0.1;
  width: 67px;
  height: 67px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.left {
  left: calc(1.5% + 1px);
  margin-left: 10px;
}
.left svg {
  margin-right: 2px;
}
.right {
  right: calc(1.5% + 1px);
  margin-right: 10px;
}
.right svg {
  margin-left: 2px;
}
.arrow:hover {
  background-color: #faf9f5;
}
.grBOFc {
  padding-top: 10px;
  padding-bottom: 30px;
}
.shortDescription p {
  text-align: left !important;
}
.longDescriptions p {
  text-align: left !important;
  margin-bottom: 1rem;
}
.longDescriptions p a, .longDescription a, .footerDescription p a{
  color:#fb7f05;
  text-decoration: none;
}
.longDescription ul{
  list-style-position: inside;
}

.hoverPointer:hover{
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .grBOFc {

    justify-content: unset;
  }
}