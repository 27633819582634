.reward-card-container{
    padding: 7px;
    flex: 33.3%;
}
.reward-card-container :hover{
    cursor: pointer;
}
.reward-card-container img{
    max-width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.reward-card-description{
    display: flex;
    padding: 30px;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-box-shadow: 0px 7px 12px -4px rgba(0,0,0,0.4); 
    box-shadow: 0px 7px 12px -4px rgba(0,0,0,0.4);
}
.reward-card-description div{
    flex:50%;
}
.reward-card-description p{
    text-transform: uppercase;
    font-weight: 400;
}
.reward-card-description h4{
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
@media only screen and (max-width: 1024px) {
    .reward-card-container{
        max-width: 380px;
        flex: 100%;
    }
}