.select-items div,.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
  }
  .select-items:hover, .same-as-selected {
    background-color: #000
  }
  .form select:active{
      border-color: aqua;
  }
  .option:hover{
      cursor:pointer;
      background-color: aqua;
  }

  .disabled {
    opacity:0.55;
    /* pointer-events: none; */
  }
  
  .help-text{
    color:var(--primary-color);
    font-size: 0.75em;
    margin-top: 0.4em;
    margin-bottom: 0;
  }
  .help-text.error{
    color:var(--form-error-state);
  }
  
  .form-control:focus{
    outline:none;
    box-shadow: none;
  }
  
  .floating-container {
    position: relative;
 /*    padding: 0.25rem 0;
    background-color:var(--form-bg); */
    border-radius: 0.4rem;
    border:solid 2px var(--form-border);
  }
  
  .checkbox-container{
    min-height:56px;
  }
  
  .floating-container:focus-within {
    border-color:var(--primary-color);
  }
  
  .floating-container.error{
    border-color:var(--form-error-state);
  }
  
  .floating-container input{
   /*  margin-top:0.8rem; */
    padding-left:0.6rem;
    padding-right:0.6rem;
    border: none;
    background: transparent;
    box-shadow: none;
    color:var(--form-input-text);
    width:100%;
    outline:none;
  }
  
  .field-prefix{
    display:none;
    margin-top:0.8rem;	
    padding-left:0.7rem;
    color:#aaa;
  }
  .field-prefix + input {
    padding-left:0;
  }
  
  .floating-container:focus-within .field-prefix, .field-prefix.has-value {
    display:block;
  }
  
  
  .floating-container textarea{
    margin-top:0.8rem;
    border: none;
    background: transparent;
    box-shadow: none;
    outline:none;
    color:var(--form-input-text);
  }
  
  .floating-container textarea:focus, .floating-container textarea:focus-visible{
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color:var(--form-input-text);	
  }
  
  .floating-container input:focus-visible,
  .floating-container input:focus,
  .floating-container select.select:focus-visible,
  .floating-container select.select:focus{
    outline: none;
  }
  
  .floating-container input:not([type='checkbox']) + label,
  .floating-container textarea + label,
  .floating-container .select + label,
  .floating-container .date-picker + label
  {
    position:absolute;
    top:0;
    left:1rem;
    transform:translateY(0.7rem);
    color:var(--form-label);
    transition: all 0.3s ease-out;
    pointer-events: none;
    font-weight: normal;
  }
  
  
  .floating-container input:focus + label, .floating-container input.has-value + label,
  .floating-container textarea:focus + label, .floating-container textarea.has-value + label,  
  .floating-container .select:focus + label, .floating-container .select.has-value + label, 
  .floating-container .date-picker:focus + label, .floating-container .date-picker.has-value + label  {
    top: -0.5rem;
      left: 1rem;
    opacity: 0.7;
    font-size: 0.7em;
    color:var(--form-label-selected);
    }
  
  
   /*  .col-lg-3 .floating-container.portrait{
    position: relative;
    padding-top: calc(133% - 4px);
    max-height: 297px;
    }  */
  
    label.image-uploader  {
    position:absolute;
    top: -0.15rem;
      left: -0.3rem;
    transform:translateY(0.5rem)  scale(0.75);
    color:var(--form-text);
    background:white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-radius:0.4rem;
    opacity: 0.7;
  
    }
  
    .select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    margin-top: 0.8rem;
      margin-left: 0.6rem;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color:var(--form-input-text);
    outline: none;
    }
  
    .select + label{
      pointer-events: none;
    }
  
  .select-triangle {
    position: absolute;
    right:0.8em;
    top:0;
    transform:translateY(1.1rem);
    width: 0.8em;
    height: 0.5em;
    background-color: var(--primary-color);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    pointer-events: none;
    }
  
    .calendar-icon {
    position: absolute;
    right:0.8em;
    top:0;
    transform:translateY(0.7rem);
    pointer-events: none;
    }
  
    /* disable numeric spinner arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  
  
  /* custom select :/ */
  .select-input{
    cursor: pointer;
    caret-color: transparent;
    color: transparent !important;
  }
  
  .select-dropdown{
    display: none;
    background: white;
    position: absolute;
    top: 29px;
    left: 0%;
    width: 100%;
    z-index: 5;
    border-radius: 0px 0px 8px 8px;
    border: 2px solid #FF692B;
    /* box-shadow: 0px 10px 12px #0000001f; */
    list-style: none;
    padding: 0.3rem 1rem;
    max-height: 284px;
    overflow-y: auto;
    border-top: none;
  }
  
  .select-dropdown::-webkit-scrollbar{
    height: 12px;
    width: 12px;
    }
    
  
  .select-dropdown li{
    padding:0.2rem 0.2rem;
    margin-left: 0;
    min-height: 2rem;
    cursor: pointer;
    font-weight: 400;
  }
  
  .select-dropdown li:hover{
    background-color: #f8f8f8;
  }
  
  .select-dropdown li.selected {
    color: #FF692B;
    font-weight: bold;

  }
  
  .select-triangle+span{
    position: absolute;
    left: 14px;
      top: 18px;
    pointer-events: none;
    font-weight: 400;
  }
  
  
  .select-dropdown.opened{
    display: block;
  }
  
  
  /* datepicker overrides */
  .react-datepicker-popper {
    min-width: 330px;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    color:white;
    background-color: var(--primary-color);
  }
  
  .react-datepicker__day--selected:hover,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover{
    background-color: var(--primary-color);
    font-weight: bold;
  }
  .react-datepicker__header{
    background-color: #fff;
  }
  
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      border-top: none;
      border-bottom-color: #fff;
  }
  
  .react-datepicker__day-name{
    color:var(--primary-color);
    font-weight: bold;
  }
  
  .react-datepicker {
      border:none;
      border-radius: 0.7rem;
      box-shadow: 0px 10px 12px #0000001f;
  }
  .react-datepicker__time-container .react-datepicker__time {
      border-bottom-right-radius: 0.7rem;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0.7rem;
  }
  .react-datepicker__header{
    border:none;
  }
  
  
  .radio-container{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  
  
  .radio-container label{
    display: unset;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom:0;
  }
  
  .radio-container input[type="radio"]:checked, .radio-container input[type="radio"]:not(:checked) {
    position: absolute;
      left: -9999px;
      width: 0;
      height: 0;
       visibility: hidden;
  }
  
  .radio-container input + label{
    flex-grow: 1;
      flex-basis: 0;
    gap: 12px;
    position: relative;
    padding: 0.25rem;
    background-color:var(--form-bg);
    border-radius: 0.4rem;
    border:solid 2px var(--form-border);
  
  }
  
  .radio-container input:checked + label{
    border-color: var(--primary-color);
  }


#contactName:focus, #contactEmail:focus, #messsage:focus, #contactSelect:focus {
  outline: none;
  border: 2px solid #FF692B !important;
}