.intro-img, .intro-title{
  flex: 50%;
}
.dust-page *{
  z-index: 1;
}
.intro-img{
  padding-right: 30px;
  text-align: right;
}
.dust-intro-section .MuiTypography-bannerTitle{
  padding: 0;
}
/* little card styles */

.little-card-outer{
  display: flex;
  padding: 20px 0;
  justify-content:center;
  flex-wrap: wrap;
}
.little-icon{
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.dust-page .arrow {
  background-color: transparent;
  opacity: 0.1;
  width: 35px;
  height: 35px;
  opacity: 1;
}
.dust-page .right{
  margin-right: 0;
}
.dust-page .left {
  margin-left: 0;
}
.little-card-container{
  background-color: #fff;
  max-width: 210px;
  text-align: center;
  padding: 30px 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 9px 5px rgba(0,0,0,0.07); 
  box-shadow: 0px 0px 9px 5px rgba(0,0,0,0.07);
  margin: 10px;
}
.little-card-container img{
  max-width: 62px;
}
.little-card-container .MuiTypography-bannerTitle{
  font-size: 23px;
  line-height: unset;
}
.little-card-container .MuiTypography-bannerText{
  color:#FF692B;
}
.little-card-container .MuiTypography-bannerText:hover{
  cursor: pointer;
}
.card-reward-container{
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
}
.dust-pools-container{
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
}
.tokenAddressesDiv {
  position: relative;
}
.tokenAddressesDiv .balCsel, .tokenAddressesDiv .jobbCsel{
  position: absolute;
  z-index: 0;
  top: -32%;
}
.little-card-container img{
  position: absolute;
  z-index: 0;
  top: -5%;
  /* width:2000px;
  left: 50%; */

  /* background-image: url("../../assets//images/dust-page/csel.png");
  background-size: contain; */
}
.add-to-metamask-c{
  display: flex;
  align-items: center;
}
.add-to-metamask-d:hover{
  cursor: pointer;
}
.jobbCsel{
left: 77%;
}
.balCsel{
  right: 77%;
  }
  .d-d-container{
    position: relative;
  }
.d-d-container .jobbCsel{
  position: absolute;
  top: -32%;
}
.tokenAddressesDiv div,.tokenAddressesDiv p{
  z-index: 2;
}
.dusting-component{
  padding: 7px;
  flex: 33.3%;
}
.dust-proofing{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  margin: 0 auto;
}
.dust-proofing .d-p-div{
  flex: 50%; 
  padding: 20px;
}
.dust-proofing .a-right{
  text-align: right;
}
.dust-proofing .a-left div{
  text-align: left;
}
.dust-proofing .MuiTypography-bannerTitle{
  text-align: left!important;
}
.dust-use-cases img{
height: 75px;
width: auto!important;
}
.dust-use-cases .MuiGrid-container{
  justify-content: center;
  }
  .dust-use-cases .MuiTypography-body1{
    max-height: 200px;
  }
.add-to-metamask-c .add-to-metamask-d{
  width: 100%;
}
.add-to-metamask-c-m {
  flex:100%;
  text-align: center;
  margin-top: 30px;
}
.add-to-metamask-c-m .add-to-metamask-d-m{
  width: 100%;
  max-width: 233px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (max-width: 1024px) {
  .dusting-component{
    flex: 100%!important;
    margin-top: 10px;
    padding: 0;
  }
  .intro-img{
    padding: 0;
  }
  .intro-img, .intro-title{
    flex: 100%;
    text-align: center;
  }
  .MuiTypography-bannerTitle{
    text-align: center!important;
  }
  .dust-proofing .a-right{
    text-align: center!important;
  }
  .dust-proofing .d-p-div{
    padding: 10px;
    flex: 100%;
  }
  .dust-proofing .a-left div{
    text-align: center!important;
  }
  .dust-proofing .d-p-div img{
    max-width: 170px;
  }
  .dust-page .section-divider{
    margin: 20px 0;
  }

}
@media only screen and (max-width: 1024px) {
  .add-to-metamask-d{
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .add-to-metamask-c-m{
    display: none;
  } 
}


.carousel-divv{
  transform: translate(0px,0px);
}