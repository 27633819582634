table {
    border-collapse: separate !important;
    border-spacing: 0;
    width: 600px;
	margin: 20px 7px;
	width:calc(100% - 14px);
}
.bordered {
    border: solid #CBC9C0 4px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 12px;
    -webkit-box-shadow: 0 1px 1px #CBC9C0;
    -moz-box-shadow: 0 1px 1px #CBC9C0;
    box-shadow: 0 1px 1px #CBC9C0;
}
.bordered td, .bordered th {
    border-left: 4px solid #CBC9C0;
    border-top: 4px solid #CBC9C0;
    padding: 10px;
    text-align: left;
	padding-left: 20px;
}
.medium-poppins{
	font-family: "poppins-medium";
}
.bordered th {
    /* background-color: #ECECEC; */
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(#F8F8F8), to(#ECECEC)); */
    /* background-image: -webkit-linear-gradient(top, #F8F8F8, #ECECEC);
    background-image: -moz-linear-gradient(top, #F8F8F8, #ECECEC);    
    background-image: linear-gradient(top, #F8F8F8, #ECECEC); */
    /* -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.8) inset;
    -moz-box-shadow:0 1px 0 rgba(255,255,255,.8) inset; */
    /* box-shadow: 0 1px 0 rgba(255,255,255,.8) inset; */
    border-top: none;
	font-weight: 500;
    /* text-shadow: 0 1px 0 rgba(255,255,255,.5); */
}
.bordered td:first-child, .bordered th:first-child {
    border-left: none;
}
.bordered th:first-child {
    -moz-border-radius: 6px 0 0 0;
    -webkit-border-radius: 6px 0 0 0;
    border-radius: 6px 0 0 0;
}
.bordered th:last-child {
    -moz-border-radius: 0 6px 0 0;
    -webkit-border-radius: 0 6px 0 0;
    border-radius: 0 6px 0 0;
}
.bordered th:only-child{
    -moz-border-radius: 6px 6px 0 0;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
}
.bordered tr:last-child td:first-child {
    -moz-border-radius: 0 0 0 6px;
    -webkit-border-radius: 0 0 0 6px;
    border-radius: 0 0 0 6px;
}
.bordered tr:last-child td:last-child {
    -moz-border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 0 6px 0;
    border-radius: 0 0 6px 0;
} 
.bordered label{
	font-size: 20px;
}
@media only screen and (max-width: 600px) {
    table td {
        padding: 5px!important;
    }
    table label{
        font-size: 15px!important;
    }
    .bordered th{
        padding-left: 10px;
    }
  }
  @media only screen and (max-width: 340px) {

    table label{
        font-size: 13px!important;
    }
  }
/* table tbody tr {
	background-color: #f9fafb;
} */