.dust-distribution{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.dust-distribution .MuiTypography-bannerTitle{
    text-align: right;
}

.one-third{
    flex: 33.3%;
}
.two-third{
    flex: 66.6%;
    text-align: right;
}
.two-third img{
    width: 710px;
    max-width: 100%;
}

@media only screen and (max-width: 1024px) {
    .dust-distribution .MuiTypography-bannerText{
        text-align: center;
        margin-bottom: 20px;
    }
    .one-third{
        flex: 100%;
    }
    .two-third{
        flex: 100%;
        text-align: center;
    }
}