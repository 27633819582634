.dust-pool-card {
  width: 100%;
  max-width: 540px;
  display: flex;
  -webkit-box-shadow: -1px 5px 9px 0px rgba(0,0,0,0.11); 
  box-shadow: -1px 5px 9px 0px rgba(0,0,0,0.11);
  border-radius: 15px;
  overflow: hidden;
  margin: 0 auto;
}

.dust-pool-card-image-container{
  width: 50%;
  position: relative;
  overflow: hidden;
  min-height: 265px;
}

.dust-pool-card-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}
.text-box {
  /* width: 50%; */
  padding: 15px;
}
.dust-pool-title {
  font-family: poppins-semibold;
  font-weight: 600;
  font-size: 22px;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
  line-height: 21px;
}
.dust-pool-card-label {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 14px;
}
.dust-pool-btn {
  border: 2px solid rgb(255, 105, 43);
  font-family: 'poppins-semibold';
  color: rgb(255, 105, 43);
  background-color: transparent;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 500;
  text-transform: uppercase;
}
.dust-pool-btn:hover {
  background-color: rgb(255, 105, 43);
  color: #fff;
  cursor: pointer;
}
.dust-pool-btn:focus {
  outline: none;
}

.dust-pool-btn.disabled{
  opacity:0.5;
}

.dust-pool-btn:hover.disabled{
  background-color: transparent;
  color: rgb(255, 105, 43);
  cursor:not-allowed;
}

@media only screen and (max-width: 600px) {
  .dust-pool-card-image-container{
    width:100%;
  }

  .dust-pool-card {
    flex-direction: column;
  }
  .dust-pool-card img{
    height: auto;
    max-width: 100%;
  }
}
