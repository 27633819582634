.chart-outer-circle{
    position: relative;
    height: 500px;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
}
.chart-outer-circle .points-img{
    position: absolute;
    left: 0;
    max-width: 100%;
}
.chart-texts{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}
.chart-p{
    font-family: 'poppins-medium';
    line-height: 17px;
    position: absolute;
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0;
}
.chart-p-visible{
    opacity: 1;
    transition: all 1s;
    transition-delay: 1.2s;
}
.chart-p-1{
    left: 5%;
    top: 5%;
    color: #4C2D60;
}
.chart-p-2{
    left: 87%;
    top: 12%;
    color: #EC8998;
}
.chart-p-3{
    left: 95%;
    top: 67%;
    color: #FF7880;
}
.chart-p-4{
    left: 90%;
    top: 75%;
    color: #F85477;
}
.chart-p-5{
    left: 37%;
    top: 97%;
    color: #FC5D83;
    text-align: left;
}
.chart-p-6{
    left: -7%;
    top: 45.5%;
    color: #8E4ABA;
}
.chart-p-7{
    left: -11%;
    top: 25%;
    color: #5F1989;
}
.chart-inner-circle{
    position: absolute;
    left: 10px;
    top: 10px;
    max-width: 100%;
}
.chart-inner-circle img{
     max-width: 100%;
    }
.chart-percentage{
    color:#fff;
    font-size: 24px;
    position:absolute;
    font-family: 'poppins-semibold';
}
.chart-0{
    left: 32%;
    top: 10%;
}
.chart-1{
    left: 71%;
    top: 26%;
}
.chart-2{
    left: 75%;
    top: 53%;
}
.chart-3{
    left: 71.5%;
    top: 60%;
}
.chart-4{
    left: 38%;
    top: 72%;
}
.chart-5{
    left: 13%;
    top: 38%;
}
.chart-6{
    left: 17%;
    top: 24%;
}
.chart-7{
display: none;
}
.chart-8{
    display: none;
    }
.playCircleAnim{
    animation: mymove 1s;
}
@keyframes mymove {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);;
    }
}
.mobile-chart{
    display: none;
}
@media only screen and (max-width: 624px) {
    .desktop-chart{
        display: none;
    }
    .mobile-chart{
        display: block;
    }
}
